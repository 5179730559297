import * as React from "react";
/** @jsx jsx */
import {jsx, css} from '@emotion/react'
import {Button, message, Modal} from "antd";
import {CSSProperties, useState} from "react";
import {useRequest} from "ahooks";
import jsonRequest from "../../../../../utils/request/jsonRequest";
import CancelButton from "../../../../buttons/CancelButton";
import ProjectCancelModal from "../../../project/ProjectCancelModal";

export type ProjectPayButtonProps = {
  project: any;
  style?: CSSProperties;
  reload: () => void;
}

const ProjectPayButton = (props: ProjectPayButtonProps) => {
  const {
    project, style = {}, reload,
  } = props

  const [isCancel, setIsCancel] = useState(false)

  const {run: run, loading: loading} = useRequest(() => {
    return jsonRequest(`/account/project/owner/${project.id}`, {
      method: 'PUT',
      data: {
        stage: project.stage,
        action: 'cancel',
      }
    })
      .then(rsp => {
        if (!rsp.success) {
          message.error(rsp.message)
        } else {
          message.success('操作成功')
        }
      }).finally(() => {
        reload();
      })
  }, {
    manual: true
  })

  const confirm = () => {
    Modal.confirm({
      title: '确认支付',
      content: '是否确认跳转支付',
      onOk: () => run(),
    })
  }



  return (<>
    <Button
      type='primary'
      onClick={() => setIsCancel(true)}
      size='large'
      danger
      {...{style, loading}}
    >撤销</Button>
    <ProjectCancelModal
      title='撤销任务?'
      label='请填写撤销原因说明'
      api={`/account/project/owner/${project.id}`}
      data={{
        stage: project.stage,
        action: 'cancel',
      }}
      project={project}
      open={isCancel}
      onSuccess={reload}
      onCancel={() => setIsCancel(false)}
    />
  </>)
}

export default ProjectPayButton;
