import * as React from "react";
/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import ProjectLayout from "../../../components/pages/project/ProjectLayout";
import styled from "@emotion/styled";
import HeaderBar from "../../../components/pages/project/HeaderBar";
import jsonRequest from "../../../utils/request/jsonRequest";
import {Button, message, Space} from "antd";
import {useEffect, useMemo, useState} from "react";
import auth from "../../../utils/auth";
import {useSelector} from "react-redux";
import * as queryString from "query-string";
import TeamInfo from "../../../components/pages/project/TeamInfo";
import workStatus from "../../../utils/options/workStatus";
import workTypes from "../../../utils/options/workTypes";
import ProjectCard from "../../../components/pages/project/ProjectCard";
import CardTitle from "../../../components/pages/project/ProjectCard/CardTitle";
import ProjectForm from "../../../components/pages/project/ProjectForm";
import DeliveryForm from "../../../components/pages/project/DeliveryForm";
import {useRequest} from "ahooks";
import ProjectDeliverActions from "../../../components/pages/personal/project/owner/ProjectDeliverActions";
import ProjectCancelButton from "../../../components/pages/personal/project/owner/ProjectCancelButton";
import projectOptions from "../../../utils/options/projectOptions";
import ProjectCommentButton from "../../../components/pages/project/ProjectCommentButton";
import ProjectComment from "../../../components/pages/project/ProjectComment";
import ProjectContactModal from "../../../components/pages/project/ProjectContactModal";
import ProjectEditCancelButton from "../../../components/pages/personal/project/owner/ProjectEditCancelButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

const Project = ({location}) => {
  auth.authorize()
  const {account} = useSelector((selector: any) => selector)
  const params = useMemo(() => {
    return queryString.parse(location.search)
  }, [location])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [showContact, setShowContact] = useState(false)
  const [editing, setEditing] = useState(false)

  const {run: loadProject, data: project, loading: loadingProject} = useRequest((id) => {
    return jsonRequest(`/account/project/owner/${id}`)
      .then(rsp => rsp.data.data)
  }, {manual: true})

  const load = (id) => {
    if (loading || !params?.id) {
      return;
    }
    setLoading(true)
    jsonRequest(`/resume/${id}`).then(rsp => {
      if (rsp.success) {
        // setReadable(true)
        setData(rsp.data.data)
        if (!rsp.data.data.resumeSetting.resumeUpdatedAt && rsp.data.data.id === account.id) {
          message.warning('请完善个人履历')
        }
      } else {
        if (rsp.data.code === 1001) {
          // setReadable(false)
          message.warning('履历已隐藏')
        } else {
          message.error(rsp.message);
        }
      }
    }).catch(console.error).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!!params?.id && !editing) {
      loadProject(params.id);
    }
  }, [params.id, editing])

  useEffect(() => {
    if (project?.contractorId) {
      load(project.contractorId)
    }
  }, [project])

  return (<>
    <ProjectLayout {...{project, loading}}>
      {
        !!data && (
          <Container>
            <HeaderBar
              title={project && projectOptions.stages[project.stage]}
              backUrl='/personal/project'
              linkText={project.stage !== 'auditing' && project.stage !== 'confirming' && '联系服务方'}
              onLinkClick={() => setShowContact(true)}
            />
            <TeamInfo
              id={data.id}
              avatar={data.avatar}
              name={data.nickname}
              title={data.resume.title}
              isShowContact={data.resumeSetting?.isShowContact}
              tags={[...(data.resume.region.acronym === 'CN' ? (data.city ? [data.city] : []) : [data.resume.region?.name]), `${data.resume.workYear}年经验`, workStatus[data.resume.workStatus] ?? data.resume.workStatus, workTypes[data.resume.workType] ?? data.resume.workType, ...(data.resume.job?.name ? [data.resume.job.name] : [])]}
              remoteWage={data.resume.remoteWage}
              siteWage={data.resume.siteWage}
              weChat={data.weChat}
              email={data.email}
              width={800}
              resume={data.resume}
              audited={data.resumeSetting?.auditStatus == 'auditSuccess'}
            />
            <ProjectCard>
              <CardTitle>项目编号 {project?.no}</CardTitle>
              <ProjectForm
                {...{project}}
                readOnly={!editing}
                payable={project.status === 'running' && project.stage === 'paying'}
                reload={() => loadProject(params.id)}
                onCancel={() => setEditing(false)}
                onSuccess={() => setEditing(false)}
                post={(values) => {
                  return jsonRequest(`/account/project/edit/${project.id}`, {
                    method: 'POST',
                    data: values,
                  }) // todo 处理 403 错误
                }}
                after={(!editing && project.status === 'running' && (
                  (
                    <Space>
                      {
                        (project.stage === 'auditing' || project.stage === 'confirming' || project.stage === 'contacting' || project.stage === 'paying') &&
                        (<>
                          {project.isEditing  ? (
                            <ProjectEditCancelButton project={project} reload={() => loadProject(params.id)}/>
                          ) : (
                            <ProjectCancelButton project={project} reload={() => loadProject(params.id)}/>
                          )}
                        </>)
                      }
                      {
                        !project.isEditing && (project.stage === 'auditing' || project.stage === 'confirming' || project.stage === 'contacting') &&
                        (<>
                          <Button size='large' type='primary' onClick={() => setEditing(true)}>编辑</Button>
                        </>)
                      }
                    </Space>
                  )) || (
                    !editing && project.status === 'platformCancel' && (
                      <Space>
                        <Button size='large' type='primary' onClick={() => setEditing(true)}>编辑</Button>
                      </Space>
                    )
                  )
                )}
              />
            </ProjectCard>
            {
              project?.tip && (
                <ProjectCard>
                  <CardTitle>平台提示</CardTitle>
                  <div
                    // @ts-ignore
                    css={css`
                  margin-top: 12px;
                  font-size: 14px;
                  font-family: PingFang SC-Regular, PingFang SC, serif;
                  font-weight: 400;
                  color: rgba(0,0,0,0.8);
                  line-height: 22px;
                `}
                  >
                    {project.tip.content}
                  </div>
                </ProjectCard>
              )
            }
            {
              (project.stage === 'delivering' || project.stage === 'finish') && (
                <ProjectCard>
                  <DeliveryForm
                    project={project}
                    readOnly
                    after={(
                      project.status === 'running' && project.stage === 'delivering' && (
                        <ProjectDeliverActions project={project} reload={() => loadProject(params.id)}/>
                      )
                    )}
                  />
                </ProjectCard>
              )
            }
            {
              project.stage === 'finish' && (
                <ProjectCard>
                  <CardTitle
                    tool={
                      <ProjectCommentButton reload={() => loadProject(params?.id)} project={project} type='owner'/>
                    }
                  >双方评价</CardTitle>
                  <ProjectComment project={project}/>
                </ProjectCard>
              )
            }
          </Container>
        )
      }
    </ProjectLayout>
    <ProjectContactModal
      open={showContact}
      onCancel={() => setShowContact(false)}
      email={project?.contractor?.email}
      avatar={project?.contractor?.avatar}
      name={project?.contractor?.nickname}
      title={project?.contractor?.title}
      audited={project?.contractor?.audited}
      socialMediaAccount={project?.contractor?.socialMediaAccount}
    />
  </>)
}

export default Project;
