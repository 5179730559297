import * as React from "react";
import {Button, message, Modal, Space} from "antd";
import {useRequest} from "ahooks";
import jsonRequest from "../../../../../utils/request/jsonRequest";
import ProjectCancelModal from "../../../project/ProjectCancelModal";
import {useState} from "react";

export type ProjectConfirmActionsProps = {
  project: any;
  reload: () => void;
}

const ProjectContactActions = (props: ProjectConfirmActionsProps) => {

  const {
    project,
    reload,
  } = props

  const [isCancel, setIsCancel] = useState(false)

  const {run: run, loading: loading} = useRequest((action: 'ok' | 'cancel') => {
    return jsonRequest(`/account/project/owner/${project.id}`, {
      method: 'PUT',
      data: {
        stage: 'delivering',
        action,
      }
    })
      .then(rsp => {
        if (!rsp.success) {
          message.error(rsp.message)
        } else {
          message.success('操作成功')
        }
      }).finally(() => {
        reload();
      })
  }, {
    manual: true
  })

  const confirm = () => {
    Modal.confirm({
      title: '确认验收',
      content: '确认验收后，平台将会把费用全部结算给服务方。',
      onOk: () => run('ok'),
    })
  }

  const reject = () => {
    Modal.confirm({
      title: '拒接验收?',
      onCancel: () => run('cancel')
    })
  }

  return (<>
    <Space size={16}>
      <Button
        type="primary"
        size='large'
        onClick={() => setIsCancel(true)}
        danger
        {...{loading}}
      >拒绝验收</Button>
      <Button
        type="primary"
        size='large'
        onClick={confirm}
        {...{loading}}
      >确认验收</Button>
    </Space>
    <ProjectCancelModal
      title='拒绝验收?'
      label='请填写拒绝原因说明'
      api={`/account/project/owner/${project.id}`}
      data={{
        stage: project.stage,
        action: 'cancel',
      }}
      project={project}
      open={isCancel}
      onSuccess={reload}
      onCancel={() => setIsCancel(false)}
    />
  </>)
}

export default ProjectContactActions
